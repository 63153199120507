





























































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { IGroup, PayloadState } from '@/types/types';
const groupModule = namespace('group');
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Field is required',
});

@Component({
  name: 'AddPaymentMethod',
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class GetLink extends mixins(WidgetMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @groupModule.Getter('getGroupDetails') details!: IGroup;

  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;

  group: IGroup = {
    id: 0,
    name: '',
    description: '',
  };

  @Watch('details')
  onContactDetailsChanged(payload: IGroup): void {
    this.group = {
      id: payload.id,
      name: payload.name,
      description: payload.description,
    };
  }

  @Emit('group')
  editGroup(): IGroup {
    return this.group;
  }

  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'edit',
      state: false,
    };
  }
}
